import axios from 'axios';

export default {
  getEnergyObjectsWithPagination: (params) => axios.post('/api/EnergyObjects/GetEnergyObjectsWithPagination', params),
  addEnergyObject: (energyObject) => axios.post('/api/EnergyObjects', energyObject),
  updateEnergyObject: (energyObject) => axios.put(`/api/EnergyObjects/${energyObject.id}`, energyObject),
  deleteEnergyObject: (id) => axios.delete(`/api/EnergyObjects/${id}`),
  mergeEnergyObjects(mainId, secondaryIds) {
    return axios.post('/api/EnergyObjects/Merge', { mainId, secondaryIds });
  },
};

