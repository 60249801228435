<template>
  <div class="my-flex-container-column">
    <energy-objects-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <energy-objects-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import EnergyObjectsFilter from '@/views/Nsi/EnergyObjects/EnergyObjectsFilter';
import EnergyObjectsTable from '@/views/Nsi/EnergyObjects/EnergyObjectsTable';

export default {
  name: 'EnergyObjects',
  components: { EnergyObjectsTable, EnergyObjectsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        ownerOrganizationFilter: [],
        dcOrganizationFilter: [],
        energyObjectTypeIdFilter: [],
        baseVoltageFilter: [],
        eoRegionFilter: [],
        isLocalNsiFilter: false,
        isRuFilter: false
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        ownerOrganizationFilter: [],
        dcOrganizationFilter: [],
        energyObjectTypeIdFilter: [],
        baseVoltageFilter: [],
        eoRegionFilter: [],
        isLocalNsiFilter: false,
        isRuFilter: false
      };
    }
  }
};
</script>

<style scoped>

</style>
